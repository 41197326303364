import { all, fork, call, put, takeEvery, take, cancel } from 'redux-saga/effects';
import { actions } from '../reducers/chat';
import { rsfDB, firestore } from '../lib/firebase';
import { getTodayDateString } from '@utils/getDateString'; // import { Notifier } from "../utils/notify/Notifier";
// import { sendNotifier } from "../utils/notify/NotifyPolicy";
// call은 동기, fork는 비동기 요청
function* syncNumberOfChat(action) { const { id, number } = action; const task = yield fork(rsfDB.syncDocument, firestore().collectionGroup('Comment').where('channelId', '==', id).where('isDeleted', '==', false).orderBy('createdAt', 'desc').limit(number), { successActionCreator: data => { let chats = []; data.forEach(doc => { var _doc$data, _doc$data$createdAt; chats = [{ id: doc.id, name: doc.data().name, type: doc.data().type, value: doc.data().value, target: (_doc$data = doc.data()) === null || _doc$data === void 0 ? void 0 : _doc$data.targetId, createdAt: ((_doc$data$createdAt = doc.data().createdAt) === null || _doc$data$createdAt === void 0 ? void 0 : _doc$data$createdAt.seconds) * 1000, channelId: doc.data().channelId }, ...chats]; }); return { type: actions.SYNC_NUMBER_OF_CHAT_SUCCESS, data: chats }; }, failureActionCreator: err => ({ type: actions.SYNC_NUMBER_OF_CHAT_FAILURE, err: err.message }) }); yield take(actions.SYNC_NUMBER_OF_CHAT_DONE); yield cancel(task); }
function* syncNumberOfObjectChat(action) { const { id, target, limit } = action; const task = yield fork(rsfDB.syncDocument, firestore().collection('Channel').doc(id).collection('Object').doc(target).collection('Comment').where('isDeleted', '==', false).orderBy('createdAt', 'desc').limit(limit), { successActionCreator: data => { const chats = []; data.forEach(doc => { var _doc$data$createdAt2; chats.unshift({ id: doc.id, name: doc.data().name, type: doc.data().type, value: doc.data().value, createdAt: ((_doc$data$createdAt2 = doc.data().createdAt) === null || _doc$data$createdAt2 === void 0 ? void 0 : _doc$data$createdAt2.seconds) * 1000, target, channelId: doc.data().channelId }); }); return { type: actions.SYNC_NUMBER_OF_OBJECT_CHAT_SUCCESS, data: chats }; }, failureActionCreator: err => ({ type: actions.SYNC_NUMBER_OF_OBJECT_CHAT_FAILURE, err: err.message }) }); yield take(actions.SYNC_NUMBER_OF_OBJECT_CHAT_DONE); yield cancel(task); }
function* postChat(action) {
    const { id, data } = action; // 댓글 입력을 진행할 경우, 유저를 저장한다.
    yield put({ type: actions.SAVE_USER, name: data.name, password: data.password });
    try {
        const snapshot = yield call(rsfDB.addDocument, "Channel/".concat(id, "/Comment"), Object.assign(Object.assign({}, data), { channelId: id, createdAt: firestore.FieldValue.serverTimestamp(), isDeleted: false, clientId: data.projectId }));
        if (data.projectId) {
            const date = getTodayDateString();
            const logId = "CommentLog-".concat(date);
            const ref = firestore().collection('Client').doc(data.projectId);
            yield ref.update({ commentCount: firestore.FieldValue.increment(1) });
            yield ref.collection('CommentLog').doc(logId).set({ date, count: firestore.FieldValue.increment(1), updatedAt: firestore.FieldValue.serverTimestamp() }, { merge: true });
        } // delete 기능을 위해 생성된 id값 추가
        yield call(rsfDB.updateDocument, "Channel/".concat(id, "/Comment/").concat(snapshot.id), { id: snapshot.id });
        yield call(rsfDB.updateDocument, "Exhibition/".concat(id), { commentCount: firestore.FieldValue.increment(1) });
        yield put({ type: actions.POST_CHAT_SUCCESS });
    }
    catch (err) {
        yield put({ type: actions.POST_CHAT_FAILURE, error: err.message });
    }
}
function* deleteChat(action) { const { channelId, commentId, password, projectId } = action; try {
    const target = yield call(rsfDB.getDocument, firestore().collection('Channel').doc(channelId).collection('Comment').where('id', '==', commentId).where('password', '==', password).limit(1));
    if (target.size > 0) {
        if (projectId) {
            const date = getTodayDateString();
            const logId = "CommentLog-".concat(date);
            const ref = firestore().collection('Client').doc(projectId);
            yield ref.update({ commentCount: firestore.FieldValue.increment(-1) });
        }
        yield call(rsfDB.updateDocument, "Channel/".concat(channelId, "/Comment/").concat(commentId), { isDeleted: true });
        yield call(rsfDB.updateDocument, "Exhibition/".concat(channelId), { commentCount: firestore.FieldValue.increment(-1) });
    }
    else
        throw new Error('비밀번호가 틀렸습니다. 비밀번호를 다시 입력해주세요');
    yield put({ type: actions.DELETE_CHAT_SUCCESS });
}
catch (err) {
    yield put({ type: actions.DELETE_CHAT_FAILURE, error: err.message });
} }
function* postObjectChat(action) {
    const { id, target, data } = action; // 댓글 입력을 진행할 경우, 유저를 저장한다.
    yield put({ type: actions.SAVE_USER, name: data.name, password: data.password });
    try {
        const snapshot = yield call(rsfDB.addDocument, "Channel/".concat(id, "/Object/").concat(target, "/Comment"), Object.assign(Object.assign({}, data), { channelId: id, targetId: target, createdAt: firestore.FieldValue.serverTimestamp(), isDeleted: false, clientId: data.projectId }));
        if (data.projectId) {
            const date = getTodayDateString();
            const logId = "CommentLog-".concat(date);
            const ref = firestore().collection('Client').doc(data.projectId);
            yield ref.update({ commentCount: firestore.FieldValue.increment(1) });
            yield ref.collection('CommentLog').doc(logId).set({ date, count: firestore.FieldValue.increment(1), updatedAt: firestore.FieldValue.serverTimestamp() }, { merge: true });
        } // delete 기능을 위해 생성된 id값 추가
        yield call(rsfDB.updateDocument, "Channel/".concat(id, "/Object/").concat(target, "/Comment/").concat(snapshot.id), { id: snapshot.id });
        yield call(rsfDB.updateDocument, "Exhibition/".concat(id, "/Paintings/").concat(target), { commentCount: firestore.FieldValue.increment(1) });
        yield call(rsfDB.updateDocument, "Exhibition/".concat(id), { commentCount: firestore.FieldValue.increment(1) }); // sendNotifier({
        //   type: Notifier.typeConst.OBJECTCOMMENT,
        //   exhibitionId: id,
        //   objectId: target,
        //   writer: data.name,
        //   content: data.value,
        // });
        yield put({ type: actions.POST_CHAT_SUCCESS });
    }
    catch (err) {
        yield put({ type: actions.POST_CHAT_FAILURE, error: err.message });
    }
}
function* deleteObjectChat(action) { const { channelId, targetId, commentId, password, projectId } = action; try {
    const target = yield call(rsfDB.getDocument, firestore().collection('Channel').doc(channelId).collection('Object').doc(targetId).collection('Comment').where('id', '==', commentId).where('password', '==', password).limit(1));
    if (target.size > 0) {
        if (projectId) {
            const date = getTodayDateString();
            const logId = "CommentLog-".concat(date);
            const ref = firestore().collection('Client').doc(projectId);
            yield ref.update({ commentCount: firestore.FieldValue.increment(-1) });
        }
        yield call(rsfDB.updateDocument, "Channel/".concat(channelId, "/Object/").concat(targetId, "/Comment/").concat(commentId), { isDeleted: true });
        yield call(rsfDB.updateDocument, "Exhibition/".concat(channelId, "/Paintings/").concat(targetId), { commentCount: firestore.FieldValue.increment(-1) });
        yield call(rsfDB.updateDocument, "Exhibition/".concat(channelId), { commentCount: firestore.FieldValue.increment(-1) });
    }
    else
        throw new Error('비밀번호가 틀렸습니다. 비밀번호를 다시 입력해주세요');
    yield put({ type: actions.DELETE_CHAT_SUCCESS });
}
catch (err) {
    yield put({ type: actions.DELETE_CHAT_FAILURE, error: err.message });
} }
function* watchSyncNumberOfChat() { yield takeEvery(actions.SYNC_NUMBER_OF_CHAT_REQUEST, syncNumberOfChat); }
function* watchSyncNumberOfObjectChat() { yield takeEvery(actions.SYNC_NUMBER_OF_OBJECT_CHAT_REQUEST, syncNumberOfObjectChat); }
function* watchPostChat() { yield takeEvery(actions.POST_CHAT_REQUEST, postChat); }
function* watchDeleteChat() { yield takeEvery(actions.DELETE_CHAT_REQUEST, deleteChat); }
function* watchPostObjectChat() { yield takeEvery(actions.POST_OBJECT_CHAT_REQUEST, postObjectChat); }
function* watchDeleteObjectChat() { yield takeEvery(actions.DELETE_OBJECT_CHAT_REQUEST, deleteObjectChat); } // function* watchUpdateGlobalData() {
//   yield takeEvery<string>(actions.UPDATE_GLOBAL_DATA_REQUEST, updateGlobalData);
// }
export default function* chatSaga() { yield all([fork(watchSyncNumberOfChat), fork(watchSyncNumberOfObjectChat), fork(watchPostChat), fork(watchDeleteChat), fork(watchPostObjectChat), fork(watchDeleteObjectChat)]); }
